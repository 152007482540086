import React from "react";
import { useHistory } from "react-router-dom";
import "./assets/homeStyles.css";
import T680_Home from "./assets/T680_Home.png";
import Custom_Home from "./assets/Custom_Home.png";
import VME from "./assets/VME_Home.png";
import ModularPower from "./assets/P940_Home.png";
import Photonics from "./assets/J720_Home.png";

import {
  P500_1 as DDG,
  J270_3 as PulseGen,
  // V120_4 as VME,
  P350_3 as WaveformGen,
  D100_1 as LaserControl,
  // J720_1 as Photonics,
  P620_3 as MeasureSim,
  // P940_2 as ModularPower,
} from "../Product/Images/ProductPhotos";

const Home = () => {
  const history = useHistory();

  // TO CHANGE THE ORDER OF PRODUCTS DISPLAYED IN THE STANDARD PRODUCT SECTION
  // REARRANGE THIS ARRAY
  const products = [
    ["Digital Delay Generators", DDG, "DDG", "Fast, multi-channel, programmable resolution sequences of digital output pulses with picosecond class jitter"],
    ["Modular Power System", ModularPower, "MPS", "The P940 chassis houses power, load, switching, and measurement submodules for aerospace, automotive, and industrial testing"],
    ["VME", VME, "VME", "High channel count control, measurement, and simulation modules including crate controllers, data acquisition, and more"],
    ["Photonics", Photonics, "PHO", "High-speed detection, conversion, and distribution of analog and digital signals"],
    ["Laser Drivers & Controllers", LaserControl, "LDC", "Standard and OEM solutions for picosecond domain time sequencing, pulse picking, and other laser driver and control needs"],
    ["Measurement & Simulation", MeasureSim, "MAS", "Measurement, simulation, and control of a wide array of instruments including tachometers, LVDTs, thermocouples, and more"],
    ["Waveform Generators", WaveformGen, "WFG", "Function and arbitrary waveform generators with complex modulation and synchronous signal generation"],
    ["Pulse Generators", PulseGen, "PSG", "Translation of slow signals into clean, fast pulses for driving E/O modulators and seed lasers, time-domain device model extraction, etc."],
  ];

  const custom = [
    " Aerospace instrumentation and simulation",
    " Picosecond timing",
    " High speed photonics and fiberoptic timing distribution",
    " Digital delay and pulse generation",
    " Precision analog and mixed signal processing",
  ];

  // Dropdown navigation
  const goToCategory = (e) => {
    const categorySelected = e.target.title;
    history.push(`/Category/${categorySelected}`);
  };

  return (
    <div>
      <div className="home-background">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div id="slogan-all">
              <div id="slogan-row">
                <div>PRECISION ELECTRONIC INSTRUMENTATION</div>
              </div>
              <div id="sub-slogan-row">
                <div>
                  Standard and custom electronics for demanding aerospace and
                  defense, scientific, laser and fiberoptic systems, and
                  industrial applications
                </div>
              </div>
            </div>
            <div>
              <div className="btn-group dropend">
                <button
                  type="button"
                  className="btn btn-light dropdown-toggle btn-lg"
                  id="home-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select Product Category
                </button>
                <ul className="dropdown-menu">
                  <li
                    onClick={goToCategory}
                    title={"VME"}
                    className="dropdown-item"
                  >
                    VME
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"DDG"}
                    className="dropdown-item"
                  >
                    Digital Delay Generators
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"PSG"}
                    className="dropdown-item"
                  >
                    Pulse Generators
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"WFG"}
                    className="dropdown-item"
                  >
                    Waveform Generators
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"LDC"}
                    className="dropdown-item"
                  >
                    Laser Drivers/Controllers
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"PHO"}
                    className="dropdown-item"
                  >
                    Photonics
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"MAS"}
                    className="dropdown-item"
                  >
                    Measurement/Simulation
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"MPS"}
                    className="dropdown-item"
                  >
                    Modular Power System
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"OEM"}
                    className="dropdown-item"
                  >
                    Custom/OEM
                  </li>
                  <li
                    onClick={goToCategory}
                    title={"LEG"}
                    className="dropdown-item"
                  >
                    Legacy
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src={T680_Home} className="img-fluid" alt="Highland product" />
            <i
              className="fa-solid fa-arrow-down fa-bounce fa-2xl"
              id="home-arrow"
            ></i>
          </div>
        </div>
      </div>

      {/* STANDARD PRODUCTS ROW */}
      <div className="row" id="products-row">
        {/* PRODUCT INTRO DIV */}
        <div className="row">
          <div className=" row products-intro">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h2>Standard Products</h2>
              <h5>
                Highland's vast catalog serves a wide range of industries and
                applications, with extensive options to satisfy diversified
                requirements.
              </h5>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

  <div>

  <div className="row">
    <div className="col-md-1 d-flex align-items-stretch"></div>
    <div className="col-md-5 d-flex align-items-stretch">
        <div className="row">
          {/* Digital Delay Generators */}
            <a href={`/Category/${products[0][2]}`} className="home-products-link">
              <div className="card mb-3" id="product-top-left">
                <div className="row g-0 product-content-home">
                  <div className="col-md-8">
                    <img src={products[0][1]} className="img-fluid rounded-start" alt={products[0][0]}/>
                  </div>
                  <div className="col-md-4">
                    <div className="card-body">
                      <h4 className="card-title">{products[0][0]}</h4>
                      <p className="card-text">{products[0][3]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
        </div>
      </div>
    <div className="col-md-5 d-flex align-items-stretch">
        <div className="row">
          {/* Modular Power System */}
          <a href={`/Category/${products[1][2]}`} className="home-products-link">
            <div className="card" id="product-top-right">
              <div className="product-content-home">
              <div className="card-body">
                <h4 className="card-title">{products[1][0]}</h4>
                <p className="card-text">{products[1][3]}</p>
                <img src={products[1][1]} className="card-img-top" alt={products[1][0]} id="product-top-right-img"/>
              </div>
              </div>
            </div>
          </a>
        </div>
    </div>
    <div className="col-md-1 d-flex align-items-stretch"></div>
  </div>

  <div className="row">
    <div className="col-md-1 d-flex align-items-stretch"></div>
    {/* VME */}
    <div className="col-md-7 d-flex align-items-stretch">
    <div className="row">
          {/* VME */}
            <a href={`/Category/${products[2][2]}`} className="home-products-link">
              <div className="card mb-3" id="product-middle-left">
                <div className="row g-0 product-content-home">
                                  <div className="col-md-4">
                    <div className="card-body">
                      <h4 className="card-title">{products[2][0]}</h4>
                      <p className="card-text">{products[2][3]}</p>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <img src={products[2][1]} className="img-fluid rounded-start" alt={products[2][0]}/>
                  </div>
                </div>
              </div>
            </a>
        </div>
    </div>
    <div className="col-md-3 d-flex align-items-stretch">
      <div className="row">
            {/* Photonics */}
            <a href={`/Category/${products[3][2]}`} className="home-products-link">
              <div className="card" id="product-middle-right">
                <div className="product-content-home">
                  <div className="card-body">
                    <h4 className="card-title">{products[3][0]}</h4>
                    <p className="card-text">{products[3][3]}</p>
                    <img src={products[3][1]} className="card-img-top" alt={products[3][0]} id="product-top-right-img"/>
                  </div>
                </div>
              </div>
            </a>
      </div>
    </div>
    <div className="col-md-1 d-flex align-items-stretch"></div>
  </div>

  <div className="row">
    <div className="col-md-1 d-flex align-items-stretch"></div>
    <div className="col-md-3 d-flex align-items-stretch">
      <div className="row">
            {/* Laser Drivers & Controllers */}
            <a href={`/Category/${products[4][2]}`} className="home-products-link">
              <div className="card" id="product-bottom-left">
                <div className="product-content-home">
                  <div className="card-body">
                    <h4 className="card-title">{products[4][0]}</h4>
                    <img src={products[4][1]} className="card-img-top" alt={products[4][0]} id="product-top-right-img"/>
                    <p className="card-text">{products[4][3]}</p>
                  </div>
                </div>
              </div>
            </a>
      </div>
    </div>
    <div className="col-md-7 d-flex align-items-stretch" id="product-bottom-right">
        <div className="row">
            {/* Measurement & Simulation */}
            <div className="col-md-4">
                <a href={`/Category/${products[5][2]}`} className="home-products-link">
                  <img src={products[5][1]} className="card-img-top triple-box" alt={products[5][0]}/>
                  <div className="card h-65">
                    <h4 className="card-title">{products[5][0]}</h4>
                    <div className="card-body">
                      <p className="card-text">{products[5][3]}</p>
                    </div>
                  </div>
                </a>
            </div>
            {/* Waveform Generators */}
            <div className="col-md-4">
                <a href={`/Category/${products[6][2]}`} className="home-products-link">
                  <img src={products[6][1]} className="card-img-top triple-box" alt={products[6][0]}/>
                  <div className="card h-65">
                      <h4 className="card-title">{products[6][0]}</h4>
                      <div className="card-body">
                        <p className="card-text">{products[6][3]}</p>
                      </div>
                  </div>
                </a>
            </div>
            {/* Pulse Generators */}
            <div className="col-md-4">
              <a href={`/Category/${products[7][2]}`} className="home-products-link">
                <img src={products[7][1]} className="card-img-top triple-box" alt={products[7][0]}/>
                <div className="card h-65">
                    <h4 className="card-title">{products[7][0]}</h4>
                    <div className="card-body">
                      <p className="card-text">{products[7][3]}</p>
                    </div>
                </div>
              </a>
            </div>
        </div>
    </div>
    <div className="col-md-1 d-flex align-items-stretch"></div>
  </div>

  </div>
      </div>


      <a href="/Category/OEM" id="custom-link">
        <div className="row" id="custom-background">
          <h2 id="custom-header">Custom Electronics</h2>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <img
              src={Custom_Home}
              className="img-fluid"
              alt="Jet Schematic"
              loading="lazy"
            ></img>
            <h5>
              Highland's engineering team can quickly adapt its products and
              technology into new designs of custom and embedded electronics.
            </h5>
          </div>
          <div className="col-md-4" id="custom-list">
            <ul className="list-group list-group-flush bg-transparent">
              {custom.map((cust, id) => (
                <li key={id} className="list-group-item bg-transparent custom-bullets">
                  <i className="fa-light fa-circle-check"></i>
                  {cust}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-2"></div>
        </div>
      </a>
    </div>
  );
};

export default Home;
