const J4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J4_PCIe_Cable.png";
const J6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J6_5V_USB_Power_Supply.png";
const J12 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J12_12V_Power_Supply.png";
const J24 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J24_24V_Power_Supply.png";
const J25 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J25_24V_Power_Supply.png";
const J27 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J27_Pigtail_Power_Cable.png";
const J40 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J40_48V_Power_Supply.png";
const J41 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J41_SMB_SMB_Cable.png";
// every J41 should have J42
const J42 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J42_SMB_SMA_Cable.png";
const J43 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J43_BNC_BNC_Cable.png";
const J44 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J44_SMA_SMA_Cable.png";
const J47 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J47_BNC_Terminator.png";
const J48 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J48_50_Ohm_SMA_Terminator.png";
const J50 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J50_50_Ohm_SMB_Terminator.png";
const J51 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J51_Shielded_Pair_SCSI_cable.png";
const J52 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J52_Shielded_Pair_SCSI_cable.png";
const J53 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J53_SMB_BNC_Cable.png";
const J55 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J55_DB25M_DB25M_Cable.png";
const J56 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J56_DB25M_DB25M_Cable.png";
const J57 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J57_D9_RS-232_Cable.png";
const J58 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J58_SCSI_SCSI_Cable.png";
const J59 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J59_ST_ST_Fiberoptic_Cable.png";
const J60 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J60_FC_FC_Fiberoptic_Cable.png";
const J61 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J61_ST_ST_Fiberoptic_Cable.png";
const J62 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J62_Male_A_Male_B_USB_Cable.png";
const J65 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J65_V365_Mating_Connector_Set.png";
const J71 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J71_Dual_DB9F_Agilent34104A_Cable.png";
const J75 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J75_D9_Banana_Cable.png";
const J76 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J76_D9_OpenCVA_Busing_Cable.png";
const J90 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J90_P900_Phoenix_Terminal_Block.png";
const J91 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J91_P940_Blank_Rear_Panel.png";
const J93 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J93_AC_Power_Cord.png";
const J94 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J94_Phoenix_Contact_Mating_Connector.png";
const J95 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J95_3U_Rack_Slides.png";
const J96 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J96_P945_Ribbon_Cable.png";
const J97 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J97_Slimline_D25_Cables.png";
const J120 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J120_PC_Cable_Driver_Board.png";
const J121 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J121_PC_Cable_Driver_Board.png";
const J192 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J192_Power_Supply.png";
const J206 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J206_6V_Power_Supply.png";
const J280 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J280_SCSI_Breakout_Board.png";
const J340 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J340_SCSI_BNC_Termination_Panel.png";
const J346 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J346_SMB_Trigger_Bus_Cable.png";
const J350 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J350_15V_Power_Supply.png";
const J732 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J732_Mounting_Flange.png";
const J470 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J470_DB25F_Isothermal_Termination_Box.png";
const J475 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/J475_DB25F_Din_Rail_Termination_Panel.png";
const P10 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P10_Rack_Mount_Shelf.png";
const P51 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P51_Mounting_Flange.png";
const P420 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P420_P400_Ovenized_Crystal_Oscillator.png";
const P426 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P426_P400_Rack_Mount.png";
const P431 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P431_P400_External_Ethernet_Communication.png";
const P450 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P450_P400_HV_Outputs.png";
const P491 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P491_Power_Supply.png";
const P492 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/P492_AC_Line_Triggering_Transformer.png";
const T163 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/T163_Butterfly_Mounting_Flange.png";
const T565 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/T565_RS232_Cable.png";
const T566 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/T566_Mounting_Flange.png";
const T567 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/T567_Mounting_Flange.png";
const T841 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/T841_T840_Bench_Test_Breakout_Board.png";
const V232 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V232_SCSI_DIN_Adapter.png";
const V233 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V233_SCSI_Termination_Panel.png";
const V372 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V372_Daisy_Chain_Cable.png";
const V451 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V451_Bessel_Filter.png";
const V548 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V548_Small_Signal_Transformer.png";
const V780 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/accessories/V780_Termination_Panel.jpg";

export const AccessoryPhotos = [
  J4,
  J6,
  J12,
  J24,
  J25,
  J27,
  J40,
  J41,
  J42,
  J43,
  J44,
  J47,
  J48,
  J50,
  J51,
  J52,
  J53,
  J55,
  J56,
  J57,
  J58,
  J59,
  J60,
  J61,
  J62,
  J65,
  J71,
  J75,
  J76,
  J90,
  J91,
  J93,
  J94,
  J95,
  J96,
  J97,
  J120,
  J121,
  J192,
  J206,
  J280,
  J340,
  J346,
  J350,
  J470,
  J475,
  J732,
  P10,
  P51,
  P420,
  P426,
  P431,
  P450,
  P491,
  P492,
  T163,
  T565,
  T566,
  T567,
  T841,
  V232,
  V233,
  V372,
  V451,
  V548,
  V780,
];
