import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductCard from "./../../Components/ProductCard/ProductCard";
import CategoryJumbotron from "../../Components/CategoryJumbotron/CategoryJumbotron";
import { Loader } from "../../Components/Loader";

const categoryContent = {
  VME: {
    title: "VME",
    text: "Since Highland Technology entered the VME market in 1992, we have maintained a consistent commitment to VME as an open, robust, and cost-effective platform for high channel count control, measurement, and simulation systems. Highland is a member of VITA, the VME standards body, and continues to release new technology into the VME market. As other bus architectures come and go, Highland remains confident that VME will maintain its position as the architecture of choice for essential, long life cycle programs.",
  },
  DDG: {
    title: "DIGITAL DELAY GENERATORS",
    text: 'Highland has introduced three unique features to digital delay generation: a "Queued Updates" feature allows time settings to be changed without corrupting ongoing timings; a "Train" feature allows multiple pulses to be generated from each trigger; and a "Frames" feature allows complex delay sweeps and pulse scenarios to be pre-loaded and rapidly executed. Digital delay and pulse generators are available in 1-6 channels, with square, Gaussian, and arbitrary waveshapes, and insertion delays as low as 10 nanoseconds.',
  },
  PSG: {
    title: "PULSE GENERATORS",
    text: "Highland Technology pulse generators translate low-level signals with slow edges into clean, fast pulses with amplitudes of up to 100V. DC and transformer coupled models are available.",
  },
  WFG: {
    title: "WAVEFORM GENERATORS",
    text: "Highland offers waveform generators across a wide range of forms and performance. From simple 4 channel function generators to our flagship arbitrary waveform generators with interchannel modulation, system mastering capability, and unlimited synchronization capability, all of our waveform generators are designed to allow synchronous signal generation for easy integration into end systems.",
  },
  LDC: {
    title: "LASER DRIVERS & CONTROLLERS",
    text: "Highland Technology offers off the shelf and OEM solutions for many laser driver and control needs including: Fast Pulse Generators for E/O Modulation, Fast Laser Diode Drivers, Seed Laser Pulse Picking, MOPA timing and control, Custom Delay and Waveform Generation, and many more!",
  },
  PHO: {
    title: "PHOTONICS",
    text: "Precision fiberoptic and free-space interfaces provide high-speed, low-jitter and low noise signal conversion, and transport of analog and digital signals.",
  },
  MAS: {
    title: "MEASUREMENT & SIMULATION",
    text: "Highland Technology offers a wide array of tools for measurement, simulation, and control. Tachometers, thermocouples, RTDs, precision voltages, current loops, strain gauges, and more can be measured or simulated by Highland's products.",
  },
  MPS: {
    title: "MODULAR POWER SYSTEM",
    text: "Highland's Modular Power System consists of a 3U rackmount enclosure with a main controller, power supply and cooling, and provision for installation of up to eight power, load, switching, or measurement modules, primarily intended for aerospace testing.",
  },
  OEM: {
    title: "CUSTOM/OEM",
    text: "Since 1984 Highland has provided custom electronics for challenging customer applications. Areas of expertise include: Picosecond timing, Precision analog and mixed signal processing, Digital delay and pulse generation, High speed photonics and fiberoptic timing distribution, and Aerospace instrumentation and simulation. Products demonstrating these capabilities are shown here.",
  },
  LEG: {
    title: "LEGACY",
    text: "Highland is committed to providing long-term availability of products and product support. The following products are not recommended for new design due to difficulty stocking components and high component costs. Highland will continue to support existing customers by producing and maintaining these designs whenever possible. New customers are encouraged to consider replacement products. Please contact our sales department to inquire about any products not shown.",
  },
};

function getContentForCategory(categorySelected) {
  const defaultContent = {
    title: "Not Found",
    text: "The selected category does not have any content.",
  };

  return categoryContent[categorySelected] || defaultContent;
}

const Category = () => {
  const categorySelected = window.location.href.slice(-3);

  const [categoryData, setCategoryData] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCategoryData = async () => {
      setIsLoading(true);
      try {
        const categoryData = await axios.get(
          `/api/products/category/${categorySelected}`
        );
        setCategoryData(categoryData.data);
      } catch (error) {
        console.log(error.response);
      } finally {
        setIsLoading(false);
      }
    };
    getCategoryData();

  }, [categorySelected]);

  const { title, text } = getContentForCategory(categorySelected);

  return (
    <div>
      <CategoryJumbotron title={title} text={text} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <ProductCard categoryData={categoryData} />
          </div>
          <div className="col-md-1"></div>
        </div>
      )}
    </div>
  );
};

export default Category;
