import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { RedirectOldPathToNewPathDict } from "./RedirectOldPathToNewPathDict";

export const CatchAll = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const newPath = RedirectOldPathToNewPathDict?.[pathname] ?? "/";

    push(newPath);
  }, [pathname, push]);

  return (
    <p style={{ marginTop: "80px", textAlign: "center" }}>Redirecting you...</p>
  );
};
