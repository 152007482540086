import React from "react";
import "./Loader.css";

export const Loader = () => {
  return (
    <div className="loading-spinner-wrapper">
      <div className="loading-spinner" />
    </div>
  );
};
