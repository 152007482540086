import React from "react";
import All_Products from "./img/All_Products.jpeg"; 
import VME from "./img/VME.png"; 
import Digital_Delay_Generators from "./img/Digital_Delay_Generators.png"; 
import Pulse_Generators from "./img/Pulse_Generators.png"; 
import Waveform_Generators from "./img/Waveform_Generators.png";
import Laser_Drivers_Controllers from "./img/Laser_Drivers_Controllers.png";
import Photonics from "./img/Photonics.png"; 
import Measurement_Simulation from "./img/Measurement_Simulation.png"; 
import Modular_Power_System from "./img/Modular_Power_System.png"; 
import Custom_OEM from "./img/OEM.png"; 
import Legacy from "./img/Legacy.png";

const backgroundImagesMap = {
  "All Products": All_Products,
  VME: VME,
  "DIGITAL DELAY GENERATORS": Digital_Delay_Generators,
  "PULSE GENERATORS": Pulse_Generators,
  "WAVEFORM GENERATORS": Waveform_Generators,
  "LASER DRIVERS & CONTROLLERS": Laser_Drivers_Controllers,
  PHOTONICS: Photonics,
  "MEASUREMENT & SIMULATION": Measurement_Simulation,
  "MODULAR POWER SYSTEM": Modular_Power_System,
  "CUSTOM/OEM": Custom_OEM,
  LEGACY: Legacy,
};

const CategoryJumbotron = ({ title, text }) => {
  const background = backgroundImagesMap[title];

  return (
    <div className="row">
        <div
          className="heroContainer img-fluid"
          style={{ backgroundImage: `url(${background})` }}
        >
          <h1 className="heroHeading">{title}</h1>
          <div className="heroBody">
            <h5 className="heroText">{text}</h5>
          </div>
        </div>
    </div>
  );
};

export default CategoryJumbotron;
